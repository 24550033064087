import React from 'react';
import { Form, Input, Button } from 'antd';
import 'antd/dist/reset.css';
import axios from 'axios';

const App = () => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log('Success:', values);
    callApi(values);
  };

  const callApi = async (data) => {
    try {
      const response = await axios.post('http://142.93.223.183:4000/api/similarity', data);
      console.log('API Response:', response.data);
      let score = response.data?.similarity_score || 0;
      score = score.trim();
    
    // Remove any leading or trailing periods
    score = score.replace(/^\.+|\.+$/g, '');

      if(score > 0.5) {
        alert(`Similarity Score: ${score} out of 1`);
      } else {
        alert(` Similarity Score: ${score} out of 1`);
      }

    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div style={{ padding: 24, maxWidth: 600, margin: '0 auto' }}>
      <h2>Similarity App</h2>
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name="word1"
          rules={[{ required: true, message: 'Please input the first word!' }]}
        >
          <Input placeholder="Word 1" />
        </Form.Item>
        <Form.Item
          name="word2"
          rules={[{ required: true, message: 'Please input the second word!' }]}
        >
          <Input placeholder="Word 2" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default App;
